function checkout () {
  const confirmButtons = document.querySelectorAll("[data-action='confirm-button']")
  const modals = document.getElementsByClassName('checkout-modal')
  const closeButtons = document.getElementsByClassName('close-checkout-modal')
  const checkoutButtons = document.getElementsByClassName('checkout-button')

    // NodeList 用の配列のループ
  for (let [index, confirmButton] of confirmButtons.entries()) {
    confirmButton.addEventListener('click', (event) => {
      modals[index].classList.add('is-active');
    });
  }

  // HTMLCollection 用の配列のループ
  Array.prototype.forEach.call(closeButtons, (closeButton, index) => {
    closeButton.addEventListener('click', (event) => {
      modals[index].classList.remove('is-active');
    })
  })
  for (let checkoutButton of checkoutButtons) {
    checkoutButton.addEventListener('click', () => {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")
      const formData = new FormData()
      const item = checkoutButton.getAttribute('data-type')
      const saveCardCheck = document.querySelector('#card-save')
      if (saveCardCheck) {
        if (saveCardCheck.checked) {
          formData.append('save_card', saveCardCheck.checked)
        }
      }
      let url
      switch (item) {
        case 'plan':
          const planId = checkoutButton.getAttribute(`data-${item}`)
          formData.append(item, planId)
          url = `/plans/${planId}/confirm`
          break;
        case 'product':
          formData.append('type', item)
          url = '/charges/products'
          break;
        default:
          const itemId = checkoutButton.getAttribute(`data-${item}`)
          formData.append(item, itemId)
          url = `/charges/${item}/${itemId}`
      }
      fetch(url, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrf
        },
        body: formData
      })
      .then(function(response) {
        if (response.status == 403) {
          window.location.href = response.headers.get('location')
        }
        if (response.status == 302) {
          window.location.reload()
        }
        return response.json()
      })
      .then(function(session) {
        const stripe = Stripe(session.key)
        return stripe.redirectToCheckout({sessionId: session.id})
      })
    })
  }
}
document.addEventListener('DOMContentLoaded', checkout);
